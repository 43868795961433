<template>
  <!--    Credit Form-->
  <validation-observer
      ref="creditForm"
      v-slot="{invalid}"
  >
    <lenon-modal
        :title="`${updateCreditMode?'Update':'Create'} User Credits`"
        :show="creditModalOpened"
        :show-overlay="deletingCredit"
        @onClose="closeCreditModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
              v-model="selectedCreditId"
              name="credits"
              placeholder="All User Credits"
              :options="credits"
              label-name="name"
              value-name="id"
              @input="populateCreditForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
              name="delete_credit"
              icon="TrashIcon"
          >
            <b-dropdown-item
                id="cd_credit"
                @click="deleteCredit()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
              name="credit_action"
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetCreditForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <p class="mt-1 text-center text-danger text-bold">This allows you to specify individual user credits, and once they run out, they will not be able to generate contents until you top it up here.</p>
      <lenon-multi-select v-if="!updateCreditMode" v-model="credit.selected_users" label-name="full_name" value-name="id" :options="users" label="Users" rules="required" placeholder="Select Users"/>
      <div class="mb-1"></div>
      <lenon-input
          v-model="credit.input_credits"
          name="input"
          placeholder="Enter input credits"
          label="Input Credits"
          rules="required"
          type="number"
      />
      <lenon-input
          v-model="credit.output_credits"
          name="output"
          placeholder="Enter output credits"
          label="Output Credits"
          rules="required"
          type="number"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
              name="cancel"
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeCreditModal()"
          />
          <lenon-button
              name="save_credit"
              :label="updateCreditMode?'Update':'Create'"
              :disabled="invalid"
              :loading="creditLoading"
              loading-text="Loading..."
              @onClick="updateCreditMode?updateCredit():createCredit()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BDropdownDivider, BDropdownItem, BRow } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect'

export default {
  name: 'CreditSetup',
  components: {
    LenonMultiSelect,
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      creditModalOpened: false,
      updateCreditMode: false,
      creditLoading: false,
      deletingCredit: false,
      selectedCreditId: null,
      credit: {
        id: null,
        input_credits: null,
        output_credits: null,
        selected_users: [],
      },
    }
  },
  computed: {
    selectedCredit() {
      return this.credits.find(ter => ter.id === this.selectedCreditId)
    },
    credits() {
      return this.$store.getters['documents/credits']
    },
    users() {
      return this.$store.getters['accountUsers/users']
    },
  },
  watch: {
    modalOpened(opened) {
      this.creditModalOpened = opened
    },
  },
  methods: {
    // credit
    resetCreditForm() {
      this.credit = {
        id: null,
        input_credits: null,
        output_credits: null,
        selected_users: [],
      }
      this.selectedCreditId = null
      this.updateCreditMode = false
      this.$refs.creditForm.reset()
    },
    populateCreditForm(credit) {
      if (this.selectedCredit) {
        this.updateCreditMode = true
        this.credit = {
          ...this.selectedCredit,
        }
      }else{
        this.resetCreditForm()
      }
    },
    closeCreditModal() {
      this.creditModalOpened = false
      this.$emit('modalClosed')
    },
    updateCredit() {
      this.error = {}
      if (!this.credit.id) {
        return
      }
      this.creditLoading = true
      this.credit.selected_users = []
      this.$http.put(`documents/credits/${this.credit.id}`,this.credit).then(res => {
            this.showSuccess('Updated credit successfully')
            this.creditLoading = false
            this.$store.commit('documents/updateCredit', res.data)
          })
          .catch(err => {
            logData(err)
            this.error = err
            this.showError('Failed to update credit')
            this.creditLoading = false
          })
    },
    createCredit() {
      this.error = {}
      this.creditLoading = true
      const users = this.credit.selected_users.map((u) => u.id);
      const credit = {...this.credit,selected_users: users}
      this.$http.post("documents/credits",credit).then(res => {
            this.showSuccess('Created credit successfully')
            this.creditLoading = false
            this.$store.commit('documents/addCredits', res.data)
            this.resetCreditForm()
          })
          .catch(err => {
            logData(err)
            this.error = err
            this.showError('Failed to create credit')
            this.creditLoading = false
          })
    },
    deleteCredit() {
      if (!this.selectedCreditId) {
        this.showInfo('Please select a credit')
        return
      }
      this.deletingCredit = true
      this.$http.delete(`documents/credits/${this.selectedCreditId}`).then(() => {
            this.deletingCredit = false
            this.showSuccess('Deleted Successfully')
            this.$store.commit('documents/removeCredit', this.selectedCreditId)
            this.selectedCreditId = null
            this.resetCreditForm()
          })
          .catch(() => {
            this.showError('Failed to delete credit')
            this.deletingCredit = false
          })
    },
  },
}
</script>
