<template>
  <div>
    <b-card title="">
      <div class="d-flex flex-column align-items-center">
        <strong style="font-size: 20px" class="mb-1">Generator Credits</strong>
        <p class="text-center"><b>Input</b> credits is the amount of credits you are charged based on the input size of
          the text prompts used to generate your lesson notes and questions.<br><b>Output</b> credits is the amount of
          credits you are charged based on the size of the generated lesson notes and questions.
          <br>In short, the more contents(lesson notes & questions) you generate, the more credits are used up. By
          default, Lenon gives you <b>2M</b> input credits and <b>1M</b> output credits every month when you subscribe
          to the documents module. You can top up at any time.</p>
        <div class="d-flex justify-content-center align-items-center" style="font-size: 18px">
          <b-badge variant="light-success" class="mr-50">
            Input
          </b-badge>
          <span class="mr-1"><b>{{ schoolCredits.input_credits }}</b></span>
          <lenon-button @onClick="showModal(true)" label="Top Up" variant="flat-primary" icon="ArrowUpIcon"/>
        </div>
        <div class="d-flex justify-content-center align-items-center" style="font-size: 18px">
          <b-badge variant="light-success" class="mr-50">
            Output
          </b-badge>
          <span class="mr-1"><b>{{ schoolCredits.output_credits }}</b></span>
          <lenon-button @onClick="showModal(false)" label="Top Up" variant="flat-primary" icon="ArrowUpIcon"/>
        </div>
        <lenon-button @onClick="showCreditsModal()" class="mt-1" variant="outline-primary"
                      label="Set User Credits" icon="SettingsIcon"
        />
        <!--        <strong style="font-size: 20px" class="mb-1 mt-3">Books Storage</strong>-->
        <!--        <p class="text-center">By default, you get 100MB of storage to upload documents to generate questions from, we recommend you only-->
        <!--          upload the section of the books you want to generate questions from, and if required, delete unused books for-->
        <!--          more space to upload new ones, otherwise contact support for an upgrade.</p>-->
        <!--        <div class="d-flex justify-content-center align-items-center" style="font-size: 18px">-->
        <!--          <b-badge variant="light-success" class="mr-50">-->
        <!--            Storage Space-->
        <!--          </b-badge>-->
        <!--          <span class="mr-1"><b>100MB</b></span>-->
        <!--          &lt;!&ndash;        <lenon-button @onClick="showModal('storage')" label="Buy More Storage" variant="flat-primary" icon="PlusIcon"/>&ndash;&gt;-->
        <!--        </div>-->
      </div>
    </b-card>
    <lenon-modal :show-overlay="loading" :ok-disabled="+creditsToPurchase<50000" ok-text="Top Up" @onOk="topUp()"
                 @onClose="()=>{showTopUpModal= false}" :show="showTopUpModal"
                 :title="inputCredits?'Input Credits':'Output Credits'"
    >
      <lenon-input rules="numeric|min_value:50000" name="credits" type="number" v-model="creditsToPurchase"
                   :label="inputCredits?'Total Input Credits':'Total Output Credits'"
      />
      <label>AMOUNT(GHS): <strong>{{ creditsToPurchase * coinPrice | currency }}</strong></label>
    </lenon-modal>
    <credit-setup @modalClosed="()=>{creditsModalOpened=false}" :modalOpened="creditsModalOpened"/>
  </div>
</template>

<script>
import { BCard, BAvatar, BBadge } from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton'
import LenonModal from '@/lenon/components/LenonModal'
import LenonInput from '@/lenon/components/LenonInput'
import CreditSetup from '@/views/documents/credits/UserCreditsSetup'
import showToast from '@/lenon/mixins/showToast'

export default {
  components: {
    CreditSetup,
    LenonInput,
    LenonModal,
    LenonButton,
    BCard,
    BAvatar,
    BBadge
  },
  mixins: [showToast],
  name: 'Index',
  data() {
    return {
      showTopUpModal: false,
      inputCredits: false,
      loading: false,
      creditsToPurchase: null,
      creditsModalOpened: null,
      inputCoinPrice: 0.00002,
      outputCoinPrice: 0.00003,
    }
  },
  computed: {
    schoolCredits() {
      return this.$store.getters['documents/schoolCredits']
    },
    coinPrice() {
      if (this.inputCredits) {
        return this.inputCoinPrice
      }
      return this.outputCoinPrice
    }
  },
  methods: {
    topUp() {
      this.loading = true
      this.$http.post(`documents/top-up-school-credits`, {
        type: this.inputCredits ? 'input' : 'output',
        total_credits: this.creditsToPurchase
      })
          .then((res) => {
            const link = document.createElement('a')
            link.href = res.data
            link.click()
          })
          .catch((err) => {
            this.showError('Credits Purchase Failed')
          })
          .finally(() => {
            this.loading = false
          })
    },
    showModal(isInput) {
      this.inputCredits = isInput
      this.showTopUpModal = true
    },
    showCreditsModal() {
      this.creditsModalOpened = true
    }
  },
  mounted() {
    this.$http.get(`documents/credits`)
        .then((res) => {
          this.$store.commit('documents/setCredits', res.data)
        })
    this.$http.get(`documents/school-credits`)
        .then((res) => {
          this.$store.commit('documents/setSchoolCredits', res.data)
        })
  }
}
</script>

<style scoped>

</style>
